<!--电能设置--基本设置---->
<template>
  <div id="energyMeterReading" v-loading="loading" :element-loading-text="$t('alarm.a0')">
     <!-- 页面标题 -->
    <div class="pageTitle">
        <my-PageTitle>{{ $t("energy.enedata043") }}</my-PageTitle>
    </div>
    <!-- 内容区域 -->
    <div class="index">
      <!-- 头部条件查询栏 -->
      <div class="index-header">
        <div class="index-header-left">
          <a-select v-model="rpt" style="width: 130px;margin-right:15px">
            <a-select-option v-for="(itme, index) in reportTypeList" :value="itme.no" :key="index">
              {{ itme.text }}
            </a-select-option>
          </a-select>
          <a-popover :getPopupContainer="triggerNode => { return triggerNode.parentNode }" placement="bottomLeft">
            <template slot="content">
                <a-table
                  :row-selection="{ selectedRowKeys: selRptPoints, onChange: onSelectChange }"
                  :columns="signalLocationColumns"
                  :data-source="rptPointList"
                  :pagination="false"
                  size="middle"
                  :scroll="{ x: 250, y: 300 }"
                  :rowKey="(record,index) => record.key"
                >
                </a-table>
            </template>
            <a-button type="primary" ghost style="margin-right:15px">
              {{ $t("energy.enedata024") }}
            <a-icon type="down" :style="{ fontSize: '12px' }"/>
            </a-button>
          </a-popover>
          <a-select v-model="templateSelect" style="width: 130px;margin-right:15px">
            <a-select-option v-for="(itme, index) in templateData" :value="itme.id" :key="index">
              {{ itme.name }}
            </a-select-option>
          </a-select>
          <!-- 日度数据 -->
          <div class="header-condition" v-if="rpt=='1'">
            <a-date-picker
              v-model="dayDateFrom"
              :allowClear="false"
              style="width: 130px;margin-right:15px"
              :disabled-date="disabledStartDate"
            />
            <my-dateContainer style="width: 160px">
              <template v-slot:start>
                <a-select v-model="time1" style="width: 100%;margin-right:15px" @dropdownVisibleChange="dropdownVisibleChange">
                  <a-select-option v-for="(itme, index) in timeList1" :value="itme" :key="index">
                    {{ itme }}
                  </a-select-option>
                </a-select>
              </template>
              <template v-slot:end>
                <a-select v-model="time2" style="width: 100%;margin-right:15px" @dropdownVisibleChange="dropdownVisibleChange">
                  <a-select-option v-for="(itme, index) in timeList2" :value="itme" :key="index" :disabled="Number(itme) <= Number(time1)">
                    {{ itme }}
                  </a-select-option>
                </a-select>
              </template>
            </my-dateContainer>
          </div>
          <!-- 年度/月度数据 -->
          <div class="header-condition" v-else>
            <my-dateContainer>
              <template v-slot:start>
                <a-date-picker
                  v-model="dateFrom"
                  :allowClear="false"
                  style="width: 100%"
                  :disabled-date="disabledStartDate"
                  @change="handleCalendarChange"
                />
              </template>
              <template v-slot:end>
                <a-date-picker
                  v-model="dateTo"
                  :mode="mode"
                  :allowClear="false"
                  style="width: 100%"
                  :disabled-date="disabledEndDate"
                  @panelChange="handlePanelChange1"
                />
              </template>
            </my-dateContainer>
          </div>
        </div>
        <div>
          <a-button-group>
            <a-button type="primary" ghost style="width: 120px"  @click="search()"> {{ $t("energy.enedata006") }} </a-button>
            <a-button type="primary" :disabled="data==null" @click="csvout"> {{ $t("energy.enedata042") }} </a-button>
        </a-button-group>

        </div>
      </div>
      <!-- 表格 -->
      <div class="index-main" ref="emrmain">
          <a-table
            :columns="columns"
            :data-source="data"
            :pagination="pagination"
            :scroll="tscroll"
            bordered size="small"
            :rowKey="(record,index) => index"
          >
          </a-table>
      </div>
    </div>
  </div>
</template>



<script>
import { getEnergyMeterReadingData , downloadEnergyCSVData,getTemplateList,getEnergyMeterReadingTableData } from "../../../api/energy"
import PageTitle from '../../../components/Title/PageTitle.vue'
import dateContainer from '../../../components/Title/dateContainer.vue'
import { downloadUrl } from "../../../utils/utils"
import moment from "moment"

export default {
  name: "energyMeterReading",
  components:{
    'my-PageTitle':PageTitle,
    'my-dateContainer':dateContainer
  },
  data() {
    return {
      loading: false,
      data:null,
      columns:null,
      // pagination:false,
      tls:this.$t('energy.enedata195'),
      pagination:{
        total: 0, //数据总数
        pageSize: 100, //每页中显示10条数据
        showTotal: (total) => {
          return this.tls.replace("${total}",total);
        }, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      tscroll:{x:1000,y:280},
      selectedRowKeys:[],
      year:0,
      reportTypeList:[],
      rpt:'1',
      selRptPoints:[],
      rptPointList:[],
      allRptPoints:0,
      dates:[],
      isSelAll:false,
      dateFrom:moment(),
      dateTo:moment(),
      mode:'date',
      dayDateFrom:moment(),
      time1:'00',
      time2:'01',
      timeList1:[],
      timeList2:[],
      signalLocationColumns:[
          {
            // title:'计量点列表',
            title:this.$t("energy.enedata025"),
            dataIndex: 'name'
          }
      ],
      selectedRowKeys: [],
      templateData:[],
      templateSelect:0,
      columnNumber:0
    };
  },
  watch:{
    rpt:{
      handler(newValue){
        if(newValue !== '1'){
          this.dateFrom = moment().subtract(1, "month").add(1, "day")
          this.dateTo = moment()
        }
      },
      deep:true
    },
    time1:{
      handler(newValue){
       console.log(newValue)
       const value = Number(newValue)
       if(String(value).length == 1){
        this.time2 = '0' + (value + 1)
       }else{
        this.time2 = String(value + 1)
       }
      },
      deep:true
    }
  },
  // 页面数据初始
  activated(){
    console.log("activated",this.$store.getters.clientId)
    if(this.$store.getters.clientId){
        console.log("activated load data")
        this.initPage()
    }
  },
  mounted() {
    // 调用表格高度计算方法
    this.tableSize();
    // 根据窗口变化计算表格高度
    window.addEventListener("resize", this.tableSize)
  },
  destroyed(){
    window.removeEventListener("resize", this.tableSize)
  },
  methods:{
     moment,
    // 表格数据导出API
    csvout(){
        let selPoint = '';
        if(this.selRptPoints.length>0){
          selPoint = this.selRptPoints.join(',')
        }
        let params =  {
            action:1,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            reportType:this.rpt,
            selPoint:selPoint,
            templateId:this.templateSelect
        };
        if(this.rpt == '1'){
          params.dateFrom = this.dayDateFrom.format('YYYY/MM/DD') + ' ' + this.time1
          params.dateTo = this.dayDateFrom.format('YYYY/MM/DD') + ' ' + this.time2
        }else{
          params.dateFrom = this.dateFrom.format('YYYY/MM/DD')
          params.dateTo = this.dateTo.format('YYYY/MM/DD')
        }
        console.log("DownloadEnergyMeterReadingData Params",params)
        this.loading = true;

        downloadEnergyCSVData(params)
        .then((res) => {
          console.log(res)
            let name = (this.$store.getters.siteServiceTypeName?this.$store.getters.siteServiceTypeName+'-':'')+this.$t('energy.enedata043')+'-'+moment().format('YYYYMMDDHHmmssSSS')+'.zip'
            console.log("DownloadEnergyMeterReadingData Result",name)
            downloadUrl(res,name)
        })
        .catch((err) => {
            this.loading = false
            console.log(err)
        })
        .finally(()=>{
            this.loading = false
        });
    },
    // 获取页面初始化的API
    async initPage(){
      let params =  {
            action:0,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            reportType:this.rpt,
            tenantId: 0
        };
        console.log("InitPage Params",params)
        this.loading = true;
        await  getEnergyMeterReadingData(params)
                .then((res) => {
                    console.log("LoadData Result",res)
                    if(res.errorCode == '00'){
                        this.reportTypeList = res.data.dayTypeList
                        let len = res.data.pointList.length
                        this.rptPointList = new Array()
                        this.allRptPoints = len
                        if(len>0){
                            for (let i = 0; i < len; i++) {
                                const element =  res.data.pointList[i]
                                let key = element.id
                                //默认全选 add by pzj20230202
                                this.selRptPoints.push(key)
                                let rp = {key:key,name:element.pointName}
                                this.rptPointList.push(rp)
                            }
                        }
                    }
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    console.log(err)
                });
      const query = {
        siteId:this.$route.query.id
      }
      await  getTemplateList(query)
              .then(res=>{
                console.log(res)
                const { data } = res
                this.templateData = data
                this.templateSelect = data[0].id
              })
              .catch(err=>{
                console.log(err)
              })
    },
    // 表格高度计算
    tableSize() {
        console.log('1',this.columnNumber)
        setTimeout(() => {
            let height = this.$refs.emrmain.clientHeight
            let width = this.columnNumber * 90
            let tableHeight = height - 115
            // if(this.selRptPoints.length > 0){
            //     tableHeight = (tableHeight - (this.$refs.rptpoint.clientHeight - 33))
            // }
            this.tscroll.y = tableHeight
            this.tscroll.x = width
            console.log('2',width)
        }, 10);
    },
    // 表格数据获取
    search(){
      let selPoint = ''
      if(this.selRptPoints.length>0){
        if(this.selRptPoints.length === this.allRptPoints){
          selPoint = 'ALL'
        }else{
          selPoint = this.selRptPoints.join(',')
        }
      }
      const params =  {
          action:1,
          clientId: this.$store.getters.clientId,
          siteId: this.$route.query.id,
          sitegrId: this.$store.getters.sitegrId,
          tenantId: 0,
          reportType:this.rpt,
          selPoint:selPoint,
          templateId:this.templateSelect
      }
      if(this.rpt == '1'){
        params.dateFrom = this.dayDateFrom.format('YYYY/MM/DD') + ' ' + this.time1
        params.dateTo = this.dayDateFrom.format('YYYY/MM/DD') + ' ' + this.time2
      }else{
        params.dateFrom = this.dateFrom.format('YYYY/MM/DD')
        params.dateTo = this.dateTo.format('YYYY/MM/DD')
      }
      this.loading = true
      console.log("getEnergyMeterReadingData Params",params)
      getEnergyMeterReadingTableData(params)
      .then((res) => {
          console.log("getEnergyMeterReadingData Result",res)
          const { data:{exceBody,excelHeader}} = res
          if(res.errorCode == '00'){
            const obj = {
              title: 'NO.',
              key:'no',
              width:60,
              customRender:this.customRenderForNo,
              align:'center',
              fixed:'left',
              children:[]
            }
            this.columnNumber = 0
            excelHeader.unshift(obj)
            this.data = exceBody
            this.recursion(excelHeader)
            this.columns = excelHeader
            this.tableSize()
          }
          setTimeout(()=>{
            this.loading = false
          },200)
      })
      .catch((err) => {
          this.loading = false;
          console.log(err);
      });
    },
    customRenderForNo(text, record, index) {
      return `${ (this.pagination.defaultCurrent - 1) * this.pagination.pageSize + index + 1 }`
    },
    // 计量点勾选API
    onSelectChange(selectedRowKeys) {
      this.selRptPoints = selectedRowKeys
    },
    // 递归方法
    recursion(list){
      for(let i = 0;i < list.length;i++){
        if(list[i].key == '1'){
          list[i].fixed = 'left'
          list[i].width = 200
        }
        if(list[i].key == '2'){
          list[i].fixed = 'left'
          list[i].width = 70
        }
        if(list[i].key == '24'){
          list[i].fixed = 'left'
          list[i].width = 80
        }
        if(list[i].key == '25'){
          list[i].fixed = 'left'
          list[i].width = 100
        }
        if(list[i].key == '26'){
          list[i].fixed = 'left'
          list[i].width = 80
        }
        if(list[i].key == '27'){
          list[i].fixed = 'left'
          list[i].width = 150
        }
        this.columnNumber = this.columnNumber + 1
        if(list[i].children.length !== 0){
          delete list[i].dataIndex
          this.recursion(list[i].children)
        }else{
          delete list[i].children
        }
      }
    },
    dropdownVisibleChange(open){
      const presentTimes = moment().format('YYYY-MM-DD')
      const futureTime = this.dayDateFrom.format('YYYY-MM-DD')
      console.log(moment(futureTime).isBefore(presentTimes))
      if(open){
        if(moment(futureTime).isBefore(presentTimes)){
          this.timeList1 = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22']
          this.timeList2 = ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23']
        }else{
          const length = Number(moment().format('HH'))
          console.log(length)
          const arr1 = []
          const arr2 = []
          for(let i = 0;i < length;i++){
            let str1 = ''
            let str2 = ''
            if(String(i).length == 1){
              str1 = '0' + String(i)
            }else{
              str1 = String(i)
            }
            if(String(i + 1).length == 1){
              str2 = '0' + String(i + 1)
            }else{
              str2 = String(i + 1)
            }
            arr1.push(str1)
            arr2.push(str2)
          }
          this.timeList1 = arr1
          this.timeList2 = arr2
        }
      }else{
        return false
      }
    },
    // 初始日期/时间选择变化回调
    handleCalendarChange(value, mode) {
      const _val = JSON.stringify(value)
      const val = JSON.parse(_val)
      this.dateTo = moment(val).endOf("day")
    },
    // 开始日期限制选择范围（未来日期不可选）
    disabledStartDate(startValue) {
      // 获取计算机当前日期
      const nowValue = moment()
      if (!startValue || !nowValue) {
        return false
      }
      // 禁用大于计算机日期的日期值
      return startValue.valueOf() > nowValue.valueOf()
    },
    // 终止日期/时间的日期不可选范围
    disabledEndDate(endValue) {
      const startValue = this.dateFrom
      const _value = JSON.stringify(startValue)
      const value = JSON.parse(_value)
      const nowValue = moment().endOf("day")
      if (!endValue || !startValue) {
        return false
      }
      if(this.rpt == '3'){
        console.log('1')
        return (
          startValue.valueOf() > endValue.valueOf() ||
          endValue.valueOf() > nowValue.valueOf() ||
          moment(value).add(12, "month").valueOf() <= endValue.valueOf()
        )
      }else{
        console.log('2')
        return (
          startValue.valueOf() > endValue.valueOf() ||
          endValue.valueOf() > nowValue.valueOf() ||
          moment(value).add(1, "month").valueOf() <= endValue.valueOf()
        )
      }
    },
    handlePanelChange1(value, mode){
      console.log(value, mode)
      if(mode !== 'year'){ this.mode = mode }
    },
  },
};
</script>

<style scoped>
#energyMeterReading {
  width: 100%;
  height: 100%;
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  position: relative;
}
.index{
  width: 100%;
  height: calc(100% - 50px);
  padding: 15px 20px;
}
.index-header{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.index-header-left{
  display: flex;
}
.header-condition {
  width: 300px;
  font-size: 14px;
  display: flex;
}
.index-main{
  width: 100%;
  height: calc(100% - 47px);
}
input {
  width: 14%;
}
div /deep/.ant-popover-inner-content{
  padding: 0px;
}
</style>


